import React from 'react';

import { Box, xcss } from '@atlaskit/primitives';

const dividerStyles = xcss({
	width: '100%',
	display: 'flex',
	borderBottom: '1px solid',
	borderColor: 'color.border',
});

type DividerProps = {
	testId?: string;
};

export const Divider = ({ testId }: DividerProps) => <Box xcss={dividerStyles} testId={testId} />;

import React, { useCallback, useContext } from 'react';
import type { FC } from 'react';

import { useQuery } from '@atlassian/ufo-apollo-log/use-query';

import { NewCommentsContext, ReactionsContext } from '@confluence/comment-context';
import { ErrorBoundary, Attribution } from '@confluence/error-boundary';
import { useSessionData } from '@confluence/session-data';
import type {
	CommentsSectionQuery as CommentsSectionQueryType,
	CommentsSectionWithoutReactionsQuery as CommentsSectionWithoutReactionsQueryType,
	CommentsSectionQueryVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CommentsSectionQuery';
import {
	CommentsSectionQuery,
	CommentsSectionWithoutReactionsQuery,
} from '@confluence/page-comments-queries/entry-points/CommentsSectionQuery.graphql';
import { DEFAULT_LIMIT } from '@confluence/page-comments-queries';
import { useCommentsDataActions } from '@confluence/comments-data';

import {
	MissedPubSubCommentEventsHandler,
	CommentEventType,
	type MissedCommentsResult,
} from '../MissedPubSubCommentEventsHandler';

import { setUpPageCommentsPubSub, removePageCommentsPubSub } from './pageCommentsPubSub';
import { reloadAndAddPageComments } from './pageCommentsOperations';

type PageCommentsPubSubHandlerProps = {
	contentId: string;
	contentType?: string | null;
};

export const PageCommentsPubSubHandler: FC<PageCommentsPubSubHandlerProps> = ({
	contentId,
	contentType,
}) => {
	const { isReactionsEnabled } = useContext(ReactionsContext);
	const { addNewComment, removeComment, clearNewComments } = useContext(NewCommentsContext);

	const { userId } = useSessionData();

	const {
		addReplyToCommentThread,
		addNewCommentThreads,
		getCurrentCommentsPanelState,
		updateCommentBody,
		updateRemovedCommentIdsMap,
	} = useCommentsDataActions();

	const { refetch } = useQuery<
		CommentsSectionQueryType | CommentsSectionWithoutReactionsQueryType,
		CommentsSectionQueryVariables
	>(isReactionsEnabled ? CommentsSectionQuery : CommentsSectionWithoutReactionsQuery, {
		variables: {
			contentId,
			offset: '',
			first: DEFAULT_LIMIT,
		},
		fetchPolicy: 'cache-and-network',
		skip: !contentId,
	});

	const subscribeToPageCommentsPubSub = useCallback(() => {
		const queryArgs = {
			isReactionsEnabled,
		};

		setUpPageCommentsPubSub({
			contentId,
			contentType,
			userId,
			reloader: refetch,
			addNewComment,
			removeComment,
			queryArgs,
			addNewCommentThreads,
			addReplyToCommentThread,
			updateCommentBody,
			updateRemovedCommentIdsMap,
			getCurrentCommentsPanelState,
		});
	}, [
		contentId,
		contentType,
		userId,
		refetch,
		addNewComment,
		removeComment,
		isReactionsEnabled,
		addNewCommentThreads,
		addReplyToCommentThread,
		updateCommentBody,
		updateRemovedCommentIdsMap,
		getCurrentCommentsPanelState,
	]);

	const unsubscribeFromPageCommentsPubSub = useCallback(() => {
		const queryArgs = {
			isReactionsEnabled,
		};

		removePageCommentsPubSub({
			contentId,
			contentType,
			userId,
			reloader: refetch,
			addNewComment,
			removeComment,
			queryArgs,
			updateRemovedCommentIdsMap,
		});
	}, [
		contentId,
		contentType,
		userId,
		refetch,
		addNewComment,
		removeComment,
		isReactionsEnabled,
		updateRemovedCommentIdsMap,
	]);

	const handleEventFetchSuccess = useCallback(
		(data: MissedCommentsResult) => {
			if (data && data.contentId === contentId) {
				if (data.commentEvents.length) {
					const commentData = data.commentEvents.map((event) => {
						return { commentId: event.id, accountId: event.accountId };
					});
					void reloadAndAddPageComments(refetch, addNewComment, commentData, userId);
				}
			}
		},
		[contentId, addNewComment, refetch, userId],
	);

	if (!userId) {
		return null;
	} else {
		return (
			<ErrorBoundary attribution={Attribution.COLLABORATION}>
				<MissedPubSubCommentEventsHandler
					contentId={contentId}
					eventType={CommentEventType.FOOTER}
					subscribeToPubSubEvents={subscribeToPageCommentsPubSub}
					unsubscribeFromPubSubEvents={unsubscribeFromPageCommentsPubSub}
					onEventFetchSuccess={handleEventFetchSuccess}
					onDocumentReturn={clearNewComments}
					isAutoReloadPageComments
				/>
			</ErrorBoundary>
		);
	}
};

import gql from 'graphql-tag';

import { commentDataFragment } from './CommentsSectionFragments.fragment';

export const CommentsSectionGetSingleCommentQuery = gql`
	query CommentsSectionGetSingleCommentQuery($commentId: ID!, $contentId: ID!) {
		comments(commentId: $commentId) {
			nodes {
				...commentDataFragment
				reactionsSummary(pageId: $contentId, contentType: "page", childType: "comment") {
					ari
					containerAri
					reactionsCount
					reactionsSummaryForEmoji {
						id
						emojiId
						count
						reacted
					}
				}
			}
		}
	}
	${commentDataFragment}
`;

export const CommentsSectionGetSingleCommentWithoutReactionsQuery = gql`
	query CommentsSectionGetSingleCommentWithoutReactionsQuery($commentId: ID!) {
		comments(commentId: $commentId) {
			nodes {
				...commentDataFragment
			}
		}
	}
	${commentDataFragment}
`;

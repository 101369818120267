import { useQuery } from '@apollo/react-hooks';

import { fg } from '@confluence/feature-gating';

import { WebPanelLocationQuery } from './WebPanelLocationQuery.graphql';

export const useConnectAppsData = ({
	contentId,
	location,
}: {
	contentId: string;
	location: string;
}) => {
	const { data } = useQuery(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		WebPanelLocationQuery,
		{
			variables: {
				contentId,
				location,
			},
			fetchPolicy: 'cache-first',
			skip: !fg('confluence_preload_webpanel_atl_general'),
		},
	);
	const panels = data?.webPanels || [];
	const webPanelsAtlGeneral = panels.map((panel) => panel?.moduleKey).join(',');
	return {
		webPanelsAtlGeneral,
	};
};

import { useQuery } from 'react-apollo';

import { isGraphQLError } from '@confluence/network';
import { isErrorMarkedAsHandled, markErrorAsHandled } from '@confluence/graphql';

import { InstanceIsInReverseTrialQuery } from './InstanceIsInReverseTrialQuery.graphql';
import type { InstanceIsInReverseTrialQuery as InstanceIsInReverseTrialQueryType } from './__types__/InstanceIsInReverseTrialQuery';

type HookType = {
	skip: boolean;
};

export const useReverseTrialEligible = ({ skip }: HookType = { skip: false }) => {
	// eslint-disable-next-line check-react-ssr-usage/no-react-ssr
	const skipQuery = skip || Boolean(process.env.REACT_SSR); // InstanceIsInReverseTrialQuery isn't preloaded and can be skipped during SSR to avoid unnecessary requests
	const { data, loading, error } = useQuery<InstanceIsInReverseTrialQueryType>(
		// eslint-disable-next-line graphql-relay-compat/no-import-graphql-operations -- Read https://go/connie-relay-migration-fyi
		InstanceIsInReverseTrialQuery,
		{
			skip: skipQuery,
		},
	);
	if (isGraphQLError(error) && !isErrorMarkedAsHandled(error)) {
		markErrorAsHandled(error);
	}
	return {
		reverseTrialCohort: data?.signUpProperties?.reverseTrial,
		loading: skipQuery ? false : loading,
		error: skipQuery ? null : error,
	};
};

import { getApolloClient } from '@confluence/graphql';
import {
	CommentsSectionGetSingleCommentQuery,
	CommentsSectionGetSingleCommentWithoutReactionsQuery,
} from '@confluence/page-comments-queries/entry-points/CommentsSectionGetSingleCommentQuery.graphql';
import type { AddCommentType } from '@confluence/comment-context';

export type PageCommentsReloadFn = (options?: any) => Promise<any>;

type UpdateCommentFn = (
	commentId: string,
	updateQueryVariables: {
		contentId: string;
		isReactionsEnabled: boolean;
	},
) => void;

export const updateComment: UpdateCommentFn = async (
	commentId,
	{ contentId, isReactionsEnabled },
) => {
	const client = await getApolloClient();
	const query = isReactionsEnabled
		? CommentsSectionGetSingleCommentQuery
		: CommentsSectionGetSingleCommentWithoutReactionsQuery;
	const variables = isReactionsEnabled
		? {
				commentId,
				contentId,
			}
		: {
				commentId,
			};

	// Query the single comment to update the cache
	void client.query({ query, variables });
};

export const reloadAndAddPageComments = (
	reloader: PageCommentsReloadFn,
	addNewComment: ({ currentUserMadeComment, newCommentId }: AddCommentType) => void,
	commentData: {
		commentId: string;
		accountId: string;
	}[],
	currentUserId: string | null,
) =>
	reloader()
		.then(() => {
			setTimeout(() => {
				// HACK: Give the comments section time to render the new comment
				commentData.forEach((data) => {
					addNewComment({
						newCommentId: data.commentId,
						currentUserMadeComment: data.accountId === currentUserId,
					});
				});
			}, 3000);
		})
		.catch((err: any) => {
			throw err;
		});

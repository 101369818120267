import { getPubSubClient, eventToAvi } from '@confluence/pubsub-client';
import type { AddCommentType } from '@confluence/comment-context';
import type {
	CommentData,
	ReplyData,
	CommentsDataMap,
	GeneralCommentLocation,
	CommentsDataState,
} from '@confluence/comments-data';
import { CommentActionType, CommentType } from '@confluence/comments-data';
import {
	type CommentsSectionGetSingleCommentQuery as CommentsSectionGetSingleCommentQueryType,
	type CommentsSectionGetSingleCommentQueryVariables,
} from '@confluence/page-comments-queries/entry-points/__types__/CommentsSectionGetSingleCommentQuery';
import { getApolloClient } from '@confluence/graphql';
import { CommentsSectionGetSingleCommentQuery } from '@confluence/page-comments-queries/entry-points/CommentsSectionGetSingleCommentQuery.graphql';
import type { ResolvedProperties } from '@confluence/inline-comments-queries';

import type { PageCommentsReloadFn } from './pageCommentsOperations';
import { updateComment, reloadAndAddPageComments } from './pageCommentsOperations';

type OnPubSubEventFn = (input: {
	userId: string | null;
	contentId: string;
	reloader?: PageCommentsReloadFn;
	addNewComment?: ({ currentUserMadeComment, newCommentId }: AddCommentType) => void;
	removeComment?: (commentId?: string) => void;
	updateQueryVariables?: {
		contentId: string;
		isReactionsEnabled: boolean;
	};
	addNewCommentThreads?: (newCommentThreads: CommentsDataMap) => void;
	addReplyToCommentThread?: (threadKey: string, reply: ReplyData, commentType: CommentType) => void;
	updateCommentBody?: (
		commentId: string,
		threadKey: string,
		newBodyValue: string,
		commentType: CommentType,
	) => void;
	updateRemovedCommentIdsMap?: (props: {
		threadKey: string;
		commentId: string;
		commentActionType: CommentActionType.DELETE_COMMENT | CommentActionType.RESOLVE_COMMENT_THREAD;
		isReply: boolean;
		commentType: CommentType;
		resolvedProperties?: {
			inlineResolveProperties: ResolvedProperties;
			inlineText: string;
		};
	}) => void;
	getCurrentCommentsPanelState?: () => CommentsDataState;
}) => (event: string, payload: any) => void;

type PubSubParams = {
	contentId: string;
	contentType?: string | null;
	userId: string | null;
	reloader: PageCommentsReloadFn;
	addNewComment: ({ currentUserMadeComment, newCommentId }: AddCommentType) => void;
	removeComment: (commentId?: string) => void;
	queryArgs: {
		isReactionsEnabled: boolean;
	};
	addNewCommentThreads?: (newCommentThreads: CommentsDataMap) => void;
	addReplyToCommentThread?: (threadKey: string, reply: ReplyData, commentType: CommentType) => void;
	updateCommentBody?: (
		commentId: string,
		threadKey: string,
		newBodyValue: string,
		commentType: CommentType,
	) => void;
	updateRemovedCommentIdsMap?: (props: {
		threadKey: string;
		commentId: string;
		commentActionType: CommentActionType.DELETE_COMMENT | CommentActionType.RESOLVE_COMMENT_THREAD;
		isReply: boolean;
		commentType: CommentType;
		resolvedProperties?: {
			inlineResolveProperties: ResolvedProperties;
			inlineText: string;
		};
	}) => void;
	getCurrentCommentsPanelState?: () => CommentsDataState;
};

export const fetchComment = async (
	contentId: string,
	commentId: string,
): Promise<CommentsSectionGetSingleCommentQueryType> => {
	const queryVariables: CommentsSectionGetSingleCommentQueryVariables = {
		commentId,
		contentId,
	};
	const commentDataResult = await getApolloClient().query<CommentsSectionGetSingleCommentQueryType>(
		{
			query: CommentsSectionGetSingleCommentQuery,
			fetchPolicy: 'network-only',
			variables: queryVariables,
		},
	);
	return commentDataResult.data;
};

export const handleAddNewComment = async ({
	commentId,
	contentId,
	addNewCommentThreads,
	addReplyToCommentThread,
}: {
	commentId: string | undefined;
	contentId: string;
	addNewCommentThreads: ((newCommentThreads: CommentsDataMap) => void) | undefined;
	addReplyToCommentThread:
		| ((threadKey: string, reply: ReplyData, commentType: CommentType) => void)
		| undefined;
}) => {
	const commentThreadDataResult = await fetchComment(contentId, commentId ?? '');
	const comment = commentThreadDataResult?.comments?.nodes?.[0];
	if (comment) {
		const createdAt = comment.version.when;
		const commentLocation = comment.location as GeneralCommentLocation;

		// Parent comment case
		if (!comment.parentId) {
			addNewCommentThreads &&
				addNewCommentThreads({
					general: {
						[comment.id]: {
							...comment,
							isUnread: true,
							isOpen: true,
							wasRemovedByAnotherUser: false,
							type: CommentType.GENERAL,
							replies: [], // Newly created comments will never have replies
							createdAt: {
								value: createdAt,
							},
							location: {
								type: 'FOOTER',
								commentResolveProperties: commentLocation.commentResolveProperties,
							},
							links: {
								webui: comment.links.webui,
								editui: null,
							},
							permissions: {
								isEditable: comment.permissions.isEditable,
								isRemovable: comment.permissions.isRemovable,
								isResolvable: true,
							},
						} as CommentData,
					},
					inline: {},
				});
		}
		// Reply case
		else {
			const threadKey = comment.ancestors[comment.ancestors.length - 1]?.id ?? comment.id;

			addReplyToCommentThread &&
				addReplyToCommentThread(
					threadKey,
					{
						...comment,
						isUnread: true,
						isOpen: true,
						wasRemovedByAnotherUser: false,
						type: CommentType.GENERAL,
						createdAt: {
							value: createdAt,
						},
						location: {
							type: 'FOOTER',
							commentResolveProperties: commentLocation.commentResolveProperties,
						},
						links: {
							webui: comment.links.webui,
							editui: null,
						},
						permissions: {
							isEditable: comment.permissions.isEditable,
							isRemovable: comment.permissions.isRemovable,
							isResolvable: true,
						},
					} as ReplyData,
					CommentType.GENERAL,
				);
		}
	}
};

export const handleUpdateComment = async ({
	commentId,
	contentId,
	updateCommentBody,
}: {
	commentId: string | undefined;
	contentId: string;
	updateCommentBody:
		| ((
				commentId: string,
				threadKey: string,
				newBodyValue: string,
				commentType: CommentType,
		  ) => void)
		| undefined;
}) => {
	const commentThreadDataResult = await fetchComment(contentId, commentId ?? '');
	const comment = commentThreadDataResult?.comments?.nodes?.[0];

	if (comment) {
		const threadKey = comment.ancestors[comment.ancestors.length - 1]?.id ?? comment.id;

		updateCommentBody &&
			updateCommentBody(comment.id, threadKey, comment.body.value, CommentType.GENERAL);
	}
};

export const handleResolveComment = async ({
	commentId,
	contentId,
	updateRemovedCommentIdsMap,
}: {
	commentId: string | undefined;
	contentId: string;
	updateRemovedCommentIdsMap:
		| ((props: {
				threadKey: string;
				commentId: string;
				commentActionType:
					| CommentActionType.DELETE_COMMENT
					| CommentActionType.RESOLVE_COMMENT_THREAD;
				isReply: boolean;
				commentType: CommentType;
				resolvedProperties?: {
					inlineResolveProperties: ResolvedProperties;
					inlineText: string;
				};
		  }) => void)
		| undefined;
}) => {
	const commentThreadDataResult = await fetchComment(contentId, commentId ?? '');
	const comment = commentThreadDataResult?.comments?.nodes?.[0];
	if (comment) {
		updateRemovedCommentIdsMap &&
			updateRemovedCommentIdsMap({
				threadKey: comment.id,
				commentId: comment.id,
				commentActionType: CommentActionType.RESOLVE_COMMENT_THREAD,
				isReply: !!comment.parentId,
				commentType: CommentType.GENERAL,
			});
	}
};
export const handleReopenComment = async ({
	commentId,
	contentId,
	addNewCommentThreads,
	getCurrentCommentsPanelState,
}: {
	commentId: string | undefined;
	contentId: string;
	addNewCommentThreads: ((newCommentThreads: CommentsDataMap) => void) | undefined;
	getCurrentCommentsPanelState: (() => CommentsDataState) | undefined;
}) => {
	if (!getCurrentCommentsPanelState) {
		return;
	}
	const { commentsDataMap } = getCurrentCommentsPanelState();
	const commentThreadDataResult = await fetchComment(contentId, commentId ?? '');
	const comment = commentThreadDataResult?.comments?.nodes?.[0];
	if (comment && commentsDataMap) {
		const commentsDataMapClone = { ...commentsDataMap };
		const commentInMap = commentsDataMapClone[CommentType.GENERAL][comment.id];
		if (commentInMap) {
			commentInMap.isOpen = true;
			addNewCommentThreads && addNewCommentThreads(commentsDataMapClone);
		}
	}
};

export const handleDeletePanelComment = async ({
	commentId,
	updateRemovedCommentIdsMap,
	getCurrentCommentsPanelState,
}: {
	commentId: string | undefined;
	updateRemovedCommentIdsMap:
		| ((props: {
				threadKey: string;
				commentId: string;
				commentActionType:
					| CommentActionType.DELETE_COMMENT
					| CommentActionType.RESOLVE_COMMENT_THREAD;
				isReply: boolean;
				commentType: CommentType;
				resolvedProperties?: {
					inlineResolveProperties: ResolvedProperties;
					inlineText: string;
				};
		  }) => void)
		| undefined;
	getCurrentCommentsPanelState: (() => CommentsDataState) | undefined;
}) => {
	if (commentId) {
		// Default to code to delete the top-level comment
		let threadKey = commentId;
		let isReply = false;

		// This should always be true, but if it isn't don't break
		// This case handles removing replies in the panel
		if (getCurrentCommentsPanelState) {
			const { commentsDataMap } = getCurrentCommentsPanelState();

			// If the comment is not in the general map, it's a reply
			// That means we need to loop through the flat arrays of replies for each parent comment to find our id
			// It's not fast since it potentially checks every comment on the page, but it's better than a network call
			if (!commentsDataMap[CommentType.GENERAL][commentId]) {
				const allGeneralComments = commentsDataMap[CommentType.GENERAL];
				for (const parentComment of Object.values(allGeneralComments)) {
					if (parentComment.replies) {
						for (const reply of parentComment.replies) {
							if (reply.id === commentId) {
								threadKey = parentComment.id;
								isReply = true;
								break;
							}
						}
					}
				}
			}
		}

		updateRemovedCommentIdsMap &&
			updateRemovedCommentIdsMap({
				threadKey,
				commentId,
				commentActionType: CommentActionType.DELETE_COMMENT,
				isReply,
				commentType: CommentType.GENERAL,
			});
	}
};

const onEvent: OnPubSubEventFn =
	({
		userId,
		contentId,
		reloader,
		addNewComment,
		removeComment,
		updateQueryVariables,
		addNewCommentThreads,
		addReplyToCommentThread,
		updateCommentBody,
		updateRemovedCommentIdsMap,
		getCurrentCommentsPanelState,
	}) =>
	(event, payload) => {
		const { commentRequestBody, accountId } = payload;
		const { commentId } = commentRequestBody;

		// We don't need to respond to events that the current user has initiated
		if (userId === accountId) {
			return;
		}

		switch (event) {
			case 'avi:confluence:created:comment':
				void handleAddNewComment({
					commentId,
					contentId,
					addNewCommentThreads,
					addReplyToCommentThread,
				});
				void reloadAndAddPageComments(
					reloader!,
					addNewComment!,
					[
						{
							commentId,
							accountId,
						},
					],
					userId,
				);
				break;
			case 'avi:confluence:resolved:comment':
				reloader &&
					reloader()
						.then(() => {
							void handleResolveComment({
								commentId,
								contentId,
								updateRemovedCommentIdsMap,
							});
						})
						.catch((err: Error) => {
							throw err;
						});
				break;
			case 'avi:confluence:deleted:comment':
				void handleDeletePanelComment({
					commentId,
					updateRemovedCommentIdsMap,
					getCurrentCommentsPanelState,
				});
				reloader &&
					reloader()
						.then(() => {
							removeComment && removeComment(commentId);
						})
						.catch((err: Error) => {
							throw err;
						});
				break;
			case 'avi:confluence:reopen:comment':
				reloader &&
					reloader()
						.then(() => {
							void handleReopenComment({
								commentId,
								contentId,
								addNewCommentThreads,
								getCurrentCommentsPanelState,
							});
						})
						.catch((err: Error) => {
							throw err;
						});
				break;
			case 'avi:confluence:updated:comment':
				void handleUpdateComment({ commentId, contentId, updateCommentBody });
				void updateComment(commentId, updateQueryVariables!);
				break;
			default:
				break;
		}
	};

export const setUpPageCommentsPubSub = ({
	contentId,
	contentType,
	userId,
	reloader,
	addNewComment,
	removeComment,
	queryArgs: { isReactionsEnabled },
	addNewCommentThreads,
	addReplyToCommentThread,
	updateCommentBody,
	updateRemovedCommentIdsMap,
	getCurrentCommentsPanelState,
}: PubSubParams) => {
	const pubSubResourceType = contentType || 'page';
	void getPubSubClient().then((client) => {
		client
			.joinChannel(pubSubResourceType, contentId)
			.finally(() => {
				void client.subscribe(
					eventToAvi('created:comment'),
					onEvent({
						userId,
						contentId,
						reloader,
						addNewComment,
						addNewCommentThreads,
						addReplyToCommentThread,
					}),
				);
				void client.subscribe(
					eventToAvi('resolved:comment'),
					onEvent({ userId, contentId, reloader, addNewComment, updateRemovedCommentIdsMap }),
				);
				void client.subscribe(
					eventToAvi('deleted:comment'),
					onEvent({
						userId,
						contentId,
						reloader,
						removeComment,
						updateRemovedCommentIdsMap,
						getCurrentCommentsPanelState,
					}),
				);
				void client.subscribe(
					eventToAvi('reopen:comment'),
					onEvent({
						userId,
						contentId,
						reloader,
						addNewComment,
						addNewCommentThreads,
						getCurrentCommentsPanelState,
					}),
				);
				void client.subscribe(
					eventToAvi('updated:comment'),
					onEvent({
						userId,
						contentId,
						updateQueryVariables: {
							contentId,
							isReactionsEnabled,
						},
						updateCommentBody,
					}),
				);
			})
			.catch((error) => {
				throw new Error(`Unable to joinChannel ${JSON.stringify(error)}`);
			});
	});
};

export const removePageCommentsPubSub = ({
	contentId,
	contentType,
	userId,
	reloader,
	addNewComment,
	removeComment,
	queryArgs: { isReactionsEnabled },
}: PubSubParams) => {
	const pubSubResourceType = contentType || 'page';

	void getPubSubClient().then((client) => {
		void client.leaveChannel(pubSubResourceType, contentId);

		void client.unsubscribe(
			eventToAvi('created:comment'),
			onEvent({ userId, contentId, reloader, addNewComment }),
		);
		void client.unsubscribe(
			eventToAvi('deleted:comment'),
			onEvent({ userId, contentId, reloader, removeComment }),
		);
		void client.unsubscribe(
			eventToAvi('updated:comment'),
			onEvent({
				userId,
				contentId,
				updateQueryVariables: {
					contentId,
					isReactionsEnabled,
				},
			}),
		);
	});
};

import { useCallback } from 'react';

import { withFlags, type FlagsStateContainer } from '@confluence/flags';
import { usePubSubEvent } from '@confluence/pubsub-client';
import { useSessionData } from '@confluence/session-data';
import { usePageContentId } from '@confluence/page-context';

import { useShowConvertToLivePageFlag } from './useShowConvertToLivePageFlag';

type ConvertToLivePageFlagProps = {
	flags: FlagsStateContainer;
};

export const ConvertToLivePageFlag = withFlags(({ flags }: ConvertToLivePageFlagProps) => {
	const [contentId] = usePageContentId();
	const { userId } = useSessionData();
	const showConvertToLivePageFlag = useShowConvertToLivePageFlag({ flags });

	const onEvent = useCallback(
		(_: any, payload: any) => {
			const { accountId } = payload;
			showConvertToLivePageFlag({ converterWasCurrentUser: accountId === userId });
		},
		[showConvertToLivePageFlag, userId],
	);

	usePubSubEvent({
		// contentId might be undefined coming from usePageContentId, skipping pubsub event if so
		skip: !contentId,
		// Empty contentId will not happen as pubsub subscription will be skipped
		contentId: contentId ?? '',
		contentType: 'page',
		eventName: 'convertToLiveEdit:page',
		onEvent,
	});

	return null;
});

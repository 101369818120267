/**
 * @graphql cc
 */
import gql from 'graphql-tag';
import { useQuery } from '@apollo/react-hooks';

import { fg } from '@confluence/feature-gating';
import { useIsLivePagesFeatureEnabled } from '@confluence/live-pages-utils/entry-points/useIsLivePagesFeatureEnabled';
import { useIsLivePageWithSSR } from '@confluence/live-pages-utils/entry-points/useIsLivePageWithSSR';

import type {
	useRenderLivePageEarlyContentSubTypeAndStatusQuery as useRenderLivePageEarlyContentSubTypeAndStatusQuery$data,
	useRenderLivePageEarlyContentSubTypeAndStatusQueryVariables as useRenderLivePageEarlyContentSubTypeAndStatusQuery$variables,
} from './__types__/useRenderLivePageEarlyContentSubTypeAndStatusQuery';

export const useRenderLivePageEarly = ({
	contentId,
	cachedSubType,
	cachedIsArchived,
	renderLivePageAsViewPage,
}: {
	contentId: string;
	cachedSubType: string | undefined;
	cachedIsArchived: boolean | undefined;
	renderLivePageAsViewPage: boolean;
}): {
	renderLivePageEarly: boolean;
	isLivePageCacheOutdated: boolean;
	isFastLoadedLivePageArchived: boolean;
	isLivePageFastLoadedFromSSR: boolean;
} => {
	const { isLivePagesFeatureEnabled } = useIsLivePagesFeatureEnabled();
	const { isLive, isTypeKnown } = useIsLivePageWithSSR();

	// To handle the scenario where the cached subtype is 'live' but outdated (it's no longer 'live'): We briefly render LivePageComponent, ContentSubTypeAndStatusQuery finishes, then
	// if ContentSubTypeAndStatusQuery returns that the subtype is not actually 'live', we will render the rest of ViewPageCommon rather than rendering LivePageComponent here.
	// To handle the scenario where cached subtype is not 'live' but outdated (it's now 'live'): We'll check for it later in this component, after loading ContentUnifiedQuery,
	// and render LivePageComponent if needed.
	const { data: latestContentData, loading: isContentDataLoading } = useQuery<
		useRenderLivePageEarlyContentSubTypeAndStatusQuery$data,
		useRenderLivePageEarlyContentSubTypeAndStatusQuery$variables
	>(
		gql`
			query useRenderLivePageEarlyContentSubTypeAndStatusQuery(
				$contentId: ID!
				$versionOverride: Int
			) @SLA {
				content(id: $contentId, version: $versionOverride) {
					nodes {
						id
						status
						subType
					}
				}
			}
		`,
		{
			variables: {
				contentId,
			},
			// We don't need to retrieve subtype if cachedSubType is not 'live' because we'll never enter LivePageComponent early.
			skip:
				isTypeKnown ||
				!(
					isLivePagesFeatureEnabled &&
					!renderLivePageAsViewPage &&
					cachedSubType === 'live' &&
					fg('confluence_frontend_livepages_fastloading')
				),
			fetchPolicy: 'network-only',
		},
	);
	// Return early if we're certain that the page is live
	if (isTypeKnown) {
		return {
			renderLivePageEarly: Boolean(isLive),
			isLivePageCacheOutdated: false,
			isFastLoadedLivePageArchived: false,
			isLivePageFastLoadedFromSSR: true,
		};
	}

	const loadedSubType = latestContentData?.content?.nodes?.[0]?.subType;
	const mostRecentSubType = isContentDataLoading ? cachedSubType : loadedSubType;
	const loadedIsArchived = latestContentData?.content?.nodes?.[0]?.status === 'archived';
	const mostRecentIsArchived = isContentDataLoading ? cachedIsArchived : loadedIsArchived;

	// If live page is archived, render through normal view page instead of live page component
	return {
		renderLivePageEarly:
			isLivePagesFeatureEnabled &&
			mostRecentSubType === 'live' &&
			!mostRecentIsArchived &&
			fg('confluence_frontend_livepages_fastloading'),
		isLivePageCacheOutdated:
			isLivePagesFeatureEnabled &&
			cachedSubType === 'live' &&
			!isContentDataLoading &&
			(loadedSubType !== 'live' || (cachedIsArchived === false && loadedIsArchived === true)) &&
			fg('confluence_frontend_livepages_fastloading'),
		isFastLoadedLivePageArchived:
			isLivePagesFeatureEnabled &&
			mostRecentSubType === 'live' &&
			loadedIsArchived === true &&
			!cachedIsArchived &&
			fg('confluence_frontend_livepages_fastloading'),
		isLivePageFastLoadedFromSSR: false,
	};
};
